<template>
  <div class="hello">
    <h1>ARTIZNO - Official Websie</h1>
    <p>
     ARTIZNO - Thai electronic music producer who have genres in progressive house, big room and feature bass.
    </p>
    <br>
    <h3>Follow & Support me</h3>
    <ul>
      <li><a href="https://soundcloud.com/artizno" target="_blank" rel="noopener"><img style="height:40px" src="../assets/soundcloud.png"></a></li>
      <li><a href="https://music.youtube.com/channel/UCgIAc2qzOpiIgNFdogLqrxQ" target="_blank" rel="noopener"><img style="height:40px" src="../assets/youtube.png"></a></li>
      <li><a href="https://twitter.com/artizno" target="_blank" rel="noopener"><img style="height:40px" src="../assets/twitter.jpeg"></a></li>
      <li><a href="https://open.spotify.com/artist/0Ojha65mA46BtDVhXCO0MT" target="_blank" rel="noopener"><img style="height:40px" src="../assets/spotify.png"></a></li>
    </ul>
    <br>
    <h2>My Tracks</h2>
    <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1323328897&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/artizno" title="ARTIZNO" target="_blank" style="color: #cccccc; text-decoration: none;">ARTIZNO</a> · <a href="https://soundcloud.com/artizno/artizno-the-pirate" title="ARTIZNO - The Pirate" target="_blank" style="color: #cccccc; text-decoration: none;">ARTIZNO - The Pirate</a></div>
    <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1315203799&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/artizno" title="ARTIZNO" target="_blank" style="color: #cccccc; text-decoration: none;">ARTIZNO</a> · <a href="https://soundcloud.com/artizno/artizno-starter-original-mix" title="ARTIZNO - Starter (Original Mix)" target="_blank" style="color: #cccccc; text-decoration: none;">ARTIZNO - Starter (Original Mix)</a></div>
    <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1306366390&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/artizno" title="ARTIZNO" target="_blank" style="color: #cccccc; text-decoration: none;">ARTIZNO</a> · <a href="https://soundcloud.com/artizno/violette-wautier-all-that-i-can-do-artizno-remix" title="Violette Wautier - All That I Can Do (Mastered Version by ARTIZNO Remix)" target="_blank" style="color: #cccccc; text-decoration: none;">Violette Wautier - All That I Can Do (Mastered Version by ARTIZNO Remix)</a></div>
    <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1298446330&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/artizno" title="ARTIZNO" target="_blank" style="color: #cccccc; text-decoration: none;">ARTIZNO</a> · <a href="https://soundcloud.com/artizno/failure-and-growth" title="ARTIZNO - Failure and Growth  (Original Mix)" target="_blank" style="color: #cccccc; text-decoration: none;">ARTIZNO - Failure and Growth  (Original Mix)</a></div>
    <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1076938714&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/artizno" title="ARTIZNO" target="_blank" style="color: #cccccc; text-decoration: none;">ARTIZNO</a> · <a href="https://soundcloud.com/artizno/artizno-keep-walking" title="ARTIZNO -  Keep Walking (Original Mix)" target="_blank" style="color: #cccccc; text-decoration: none;">ARTIZNO -  Keep Walking (Original Mix)</a></div>
    <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1314511780&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/artizno" title="ARTIZNO" target="_blank" style="color: #cccccc; text-decoration: none;">ARTIZNO</a> · <a href="https://soundcloud.com/artizno/4eve-boutchya-artizno-remix" title="4EVE - Boutchya (ARTIZNO Remix)" target="_blank" style="color: #cccccc; text-decoration: none;">4EVE - Boutchya (ARTIZNO Remix)</a></div>
    <br>
    <!-- <h3>Contract</h3>
    
    <ul>
      <li><a href="https://router.vuejs.org" target="_blank" rel="noopener">vue-router</a></li>
      <li><a href="https://vuex.vuejs.org" target="_blank" rel="noopener">vuex</a></li>
      <li><a href="https://github.com/vuejs/vue-devtools#vue-devtools" target="_blank" rel="noopener">vue-devtools</a></li>
      <li><a href="https://vue-loader.vuejs.org" target="_blank" rel="noopener">vue-loader</a></li>
      <li><a href="https://github.com/vuejs/awesome-vue" target="_blank" rel="noopener">awesome-vue</a></li>
    </ul> -->
  </div>
</template>

<script>
// import { Facebook } from 'vue-socialmedia-share';
export default {
  name: 'HelloWorld',
  components:{
    
  }
  // props: {
  //   msg: String
  // }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
p{
   color: azure;
   font-size: 20px;
}
h1,h2,h3 {
  margin: 40px 0 0;
  color: azure;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
